import {datadogLogs} from '@datadog/browser-logs';

let {ENVIRONMENT_KEY, APP_VERSION, DD_SERVICE} = window.rideOctane.settings;

if (ENVIRONMENT_KEY !== 'local') {
    datadogLogs.init({
        clientToken: 'pub5168ffb84b825abb98cf94d0e01a3a4b',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        forwardReports: ['csp_violation'],
        sessionSampleRate: 100,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true
    });
    const optionalConfigs = {
        env: ENVIRONMENT_KEY,
        version: APP_VERSION,
        service: DD_SERVICE,
    };
    // set the optionalConfigs as long as they are set
    for (let [config, value] of Object.entries(optionalConfigs)) {
        if (value) {
            datadogLogs.addLoggerGlobalContext(config, value);
        }
    }
}
