import * as Sentry from '@sentry/react';

const {SENTRY_RELEASE, SENTRY_DSN_CLIENT, ENVIRONMENT_KEY} = window.rideOctane.settings;
if (SENTRY_DSN_CLIENT && SENTRY_RELEASE) {
    Sentry.init({
        dsn: SENTRY_DSN_CLIENT,
        release: SENTRY_RELEASE,
        environment: ENVIRONMENT_KEY,
    });
}
