// do this first!
import './browser-logger';
import './datadog-rum';
import './sentry';
import './styles';

import ReactGA from 'react-ga';

import {setupPageClient} from "./setup-page-client";

// initialize ga
const {GOOGLE_ANALYTICS_ID} = window.rideOctane.settings;
if (!GOOGLE_ANALYTICS_ID) {
    console.warn('No GA ID found.');
} else {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
}

// setup application
setupPageClient();
