import { datadogRum } from '@datadog/browser-rum';

const {ENVIRONMENT_KEY, APP_VERSION, DD_SERVICE} = window.rideOctane.settings;
let app_id = '83cc062c-760e-452e-a242-a73ebf9230f7';
let token = 'pubc451584076296709f2632859a8c148ab';

if (ENVIRONMENT_KEY === 'production') {
    app_id = '6711bb76-d6b3-4533-9429-8d061c48cabd';
    token = 'pub61db88881f89f98ce5c53daf6b6b042e';
}

const dataDogConfig = {
    applicationId: app_id,
    clientToken: token,
    site: 'datadoghq.com',
    sessionSampleRate: 0,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
};

const optionalConfigs = {
    env: ENVIRONMENT_KEY,
    version: APP_VERSION,
    service: DD_SERVICE,
};

// set the optionalConfigs as long as they are set
for (let [config, value] of Object.entries(optionalConfigs)) {
    if (value) {
        dataDogConfig[config] = value;
    }
}

datadogRum.init(dataDogConfig);
