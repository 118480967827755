import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "mobx-react";
import {Router} from "react-router-dom";


/**
 * Sets up the page (store and component) on the Client
 */
export function setupPageClient() {
    const {isMarketplace, isCompleteMyPaperwork, isApplicantCompleteMyPaperwork, 
        isPage404, isPage500, isSitemapHtml, isConsentForm, isPrequalFlows} = window.rideOctane;

    // Complete My Paperwork
    if (isCompleteMyPaperwork) {
        return import('./app/pages/complete-my-paperwork')
            .then(({completeMyPaperwork}) => _setupPage(completeMyPaperwork));
    }

    // Applicant Complete My Paperwork
    if (isApplicantCompleteMyPaperwork) {
        return import('./app/pages/complete-my-paperwork')
            .then(({applicantCompleteMyPaperwork}) => _setupPage(applicantCompleteMyPaperwork));
    }

    // Consent Form
    if (isConsentForm) {
        return import('./app/pages/consent-form')
            .then(({consentForm}) => _setupPage(consentForm));
    }

    // Sitemap HTML
    if (isSitemapHtml) {
        return import('./app/pages/sitemap-html')
            .then(({sitemapHtml}) => _setupPage(sitemapHtml));
    }

    // Vehicle Research
    if (isMarketplace) {
        return import('./app/pages/marketplace')
            .then(({marketplace}) => _setupPage(marketplace));
    }

    // Prequal Flows
    if (isPrequalFlows  ) {
        return import('./app/pages/flows')
            .then(({flows}) => _setupPage(flows));
    }

    // Page Not Found
    if (isPage404) {
        // force refresh on browser back or forward button for 400
        window.addEventListener('popstate', () => window.document.location.reload());


        // use an event listener to activate links
        function eventLinkAttachment(link) {
            link.addEventListener('click', () => window.document.location.assign(link.href));
        }

        // since dropdown links don't exist until click,
        // attach an observer with this callback
        function dropdownLinkAttachment(dropdown_link, observer) {
            let dropdown_items = window.document.getElementsByClassName('dropdown-item');
            for (let item of dropdown_items) {
                eventLinkAttachment(item);
            }
            observer.disconnect();
        }

        for (let link of document.links) {
            // Attach observer for dropdown link
            if (link.className.includes('dropdown-toggle')) {
                const dropdownObserver = new MutationObserver(() => dropdownLinkAttachment(link));
                dropdownObserver.observe(link, {attributes: true});
            }

            // Otherwise, it's a regular link
            else {
                eventLinkAttachment(link);
            }
        }

        return import('./app/pages/404-not-found')
            .then(({notFound})  => _setupPage(notFound))
    }

    // Server Error
    if (isPage500) {
        // force refresh on browser back or forward button for 500
        window.addEventListener('popstate', () => window.document.location.reload());
        return import('./app/pages/500-server-error')
            .then(({serverError}) => _setupPage(serverError))
    }
}

/**
 * Sets up the given page
 *  - initializes the store and component for the page
 *  - updates initial state with state passed from server
 *
 * @param {function} page
 */
function _setupPage(page) {
    const {store, component} = page();
    const initialState = window.rideOctane;

    window.rideOctaneStore = store
        .setInitialState({
            ...initialState,
            isUXServer: false,
            isUXServerRunning: false,
        })
        .preRenderSetup({
            location: window.location,
        })
        .render((stores, done) => {
            ReactDOM.hydrate(
                <Provider {...stores}>
                    <Router history={stores.historyStore.history}>
                        {component}
                    </Router>
                </Provider>,
                window.document.getElementById('root'),
                done
            );
        })
        .postRenderSetup();
}
